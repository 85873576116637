import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby";

import { colors } from '../styles/variables';

import Seo from "../components/seo";
import PageLayout from "../components/layout/PageLayout";

import {
    ABOUT_US_SECTION_CONFIG,
    NUMBER_DATA_SECTION_CONFIG,
    HOUSES_SECTION_CONFIG,
    NEWS_SECTION_CONFIG,
    HISTORY_SECTION_CONFIG,
    SPONSORS_SECTION_CONFIG,
} from "../content/homepage_content_config";

import { 
    HeroSliderSection, 
    QuoteSection, 
    NumberDataSection,
    CardSection,
    GalleryVsDescriptionSection,
    NewsSection,
    ImageBackgroundSection,
    LogoSliderSection,
} from "../components/sections";

import { cmsNewsDataMapper } from '../utils/helpers';

const IndexPage = ({ location, data }) => {
    const [isPageScrollLocked, setIsPageScrollLocked] = useState(false);
    const [isCmsDataLoading, setIsCmsDataLoading] = useState(true);
    const [cmsNewsData, setCmsNewsData] = useState([]);

    const { 
        allPrismicNewsAndEvents, 
        slideImage1, 
        slideImage2, 
        slideImage3, 
        historyImage,
        sponsorLogotypeOne,
        sponsorLogotypeTwo,
        sponsorLogotypeThree,
        cardItemImage1,
        cardItemImage2,
    } = data;

    const startGraphicQuery = {
        slideImage1,
        slideImage2,
        slideImage3,
        historyImage,
        cardItemImage1,
        cardItemImage2,
    };

    const logotypeList = [
        {
            id: 1,
            logo: sponsorLogotypeOne,
            alt: 'Lions',
        },
        {
            id: 2,
            logo: sponsorLogotypeTwo,
            alt: 'Sobtrade',
        },
        {
            id: 3,
            logo: sponsorLogotypeThree,
            alt: 'Biedronka'
        },
    ];

    const newsData = { allPrismicNewsAndEvents };

    useEffect(() => {
        setCmsNewsData(cmsNewsDataMapper(newsData));
  
        setIsCmsDataLoading(false);
    }, []);

    return ( 
        <>
            <Seo
                title="Strona główna | P.O.W. Ignaś i Helenka w Kaliskach"
                description="Placówki Opiekuńczo-Wychowawcze Ignaś i Helenka w Kaliskach. Dom dziecka w Kaliskach. Dom dziecka Julin."
            />

            <PageLayout
                location={ location }
                isPageScrollLocked={ isPageScrollLocked }
                navigationTransparentOnTop
            >
                <HeroSliderSection imagesQuery={ startGraphicQuery } />

                <QuoteSection />

                <NewsSection 
                    config={ NEWS_SECTION_CONFIG }
                    newsData={ cmsNewsData }
                    isLoading={ isCmsDataLoading }
                />

                <GalleryVsDescriptionSection
                    config={ ABOUT_US_SECTION_CONFIG }
                    setIsPageScrollLocked={ setIsPageScrollLocked }
                />

                <NumberDataSection config={ NUMBER_DATA_SECTION_CONFIG } />

                <CardSection 
                    imagesQuery={ startGraphicQuery } 
                    config={ HOUSES_SECTION_CONFIG }
                />

                <ImageBackgroundSection
                    config={ HISTORY_SECTION_CONFIG }
                    backgroundImage={ startGraphicQuery.historyImage }
                    offset={[-65, 40]}
                />

                <LogoSliderSection
                    config={ SPONSORS_SECTION_CONFIG }
                    tileList={ logotypeList }
                    background={ colors.ddGrey200 }
                />
            </PageLayout>
        </>
    );
};

export const query = graphql`
    query NewsAndEvenetsQueryHomepage {
        allPrismicNewsAndEvents(
            sort: {fields: first_publication_date, order: DESC}
            limit: 3
        ) {
            nodes {
                uid
                first_publication_date
                data {
                    news_date
                    news_title {
                        text
                    }
                    news_text {
                        text
                    }
                    news_main_image {
                        alt
                        url
                        fluid {
                            src
                        }
                    }
                }
            }
        }
        slideImage1: file(relativePath: { eq: "DDhero1.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 70)
            }
        }
        slideImage2: file(relativePath: { eq: "DDhero2.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 70)
            }
        }
        slideImage3: file(relativePath: { eq: "DDhero3.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 70)
            }
        }
        historyImage: file(relativePath: { eq: "DDhistory2new.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 70)
            }
        }
        sponsorLogotypeOne: file(relativePath: { eq: "lions-logotype.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 70)
            }
        }
        sponsorLogotypeTwo: file(relativePath: { eq: "sobtrade-logotype.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 70)
            }
        }
        sponsorLogotypeThree: file(relativePath: { eq: "biedronka-logotype.png"}) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 70)
            }
        }
        cardItemImage1: file(relativePath: { eq: "DD_nasze_domy_ignas.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
            }
        }
        cardItemImage2: file(relativePath: { eq: "DD_nasze_domy_helenka.jpg" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
            }
        }
    }
`;

export default IndexPage;
